/* Text color changes */
*[class^='ant-']{
  color: rgb(56,58,107);
}

.current {
  color: #fff !important;
}

.ant-menu-horizontal > .ant-menu-item a,
.ant-typography,
.ant-list-item-meta-title {
  color: rgb(56,58,107) !important;
}
/* end of text color changes */

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #ff65b3 !important;
}

.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #ff65b3 !important;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 1rem;
}

.ant-notification-notice {
  border-radius: 1rem !important;
}

.ant-popover-inner {
  border-radius: 1rem !important;
}

.ant-layout {
  background-color: none transparent !important;
}