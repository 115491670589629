@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&display=swap);
.identicon {
  border-radius: 50px;
}

/* Text color changes */
*[class^='ant-']{
  color: rgb(56,58,107);
}

.current {
  color: #fff !important;
}

.ant-menu-horizontal > .ant-menu-item a,
.ant-typography,
.ant-list-item-meta-title {
  color: rgb(56,58,107) !important;
}
/* end of text color changes */

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #ff65b3 !important;
}

.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #ff65b3 !important;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 1rem;
}

.ant-notification-notice {
  border-radius: 1rem !important;
}

.ant-popover-inner {
  border-radius: 1rem !important;
}

.ant-layout {
  background-color: none transparent !important;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html {
  background: none transparent !important;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background: none transparent !important;
  font-family: 'Comfortaa', cursive !important;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

.ant-modal-content {
  border-radius: 24px !important;
}

@-webkit-keyframes pulse {
  0% { -webkit-transform: scale(1); opacity: 1; }
  50% { -webkit-transform: scale(1); opacity: 0.5; }
  100% { -webkit-transform: scale(1); opacity: 1; }
}

@keyframes pulse {
  0% { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
  50% { -webkit-transform: scale(1); transform: scale(1); opacity: 0.5; }
  100% { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
}

.pulse {
  -webkit-animation: pulse 1.5s infinite ease-in-out; 
  animation: pulse 1.5s infinite ease-in-out;
}

